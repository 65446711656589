import Cookie from 'js-cookie';

const calculate_first_impression = function() {
  const first_impression_cookie = Cookie.get('genius_first_impression');

  if (first_impression_cookie) {
    const first_impression_timestamp = Number(first_impression_cookie);
    if (Date.now() - first_impression_timestamp > 30) {
      return false;
    }
  } else {
    const expiry = Date.now() + 60 * 60 * 24;
    document.cookie = `genius_first_impression=${Date.now()};path=/;expires=${expiry}`;
  }
  return true;
};

export default function page_targeting() {
  return {
    first_impression: calculate_first_impression().toString(),
    stack: 'genius',
  };
}
